// Generated by Framer (04e0834)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ckWuiORgz", "Dzy7ZCHI4"];

const serializationHash = "framer-lxrah"

const variantClassNames = {ckWuiORgz: "framer-v-dtxns5", Dzy7ZCHI4: "framer-v-81a1kz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Close: "Dzy7ZCHI4", Default: "ckWuiORgz"}

const getProps = ({click, click2, height, id, width, ...props}) => { return {...props, OFOh88QD2: click2 ?? props.OFOh88QD2, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ckWuiORgz", vYAaxf3ak: click ?? props.vYAaxf3ak} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any;click2?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, vYAaxf3ak, OFOh88QD2, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ckWuiORgz", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1sppazv = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (vYAaxf3ak) {const res = await vYAaxf3ak(...args);
if (res === false) return false;}
if (OFOh88QD2) {const res = await OFOh88QD2(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-dtxns5", className, classNames)} data-border data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"ckWuiORgz"} onTap={onTap1sppazv} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-4c2d4390-2a5c-44d9-8c88-e13922773f89, rgb(156, 255, 51))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}} variants={{Dzy7ZCHI4: {"--border-bottom-width": "0px", "--border-left-width": "0px", "--border-right-width": "0px", "--border-top-width": "0px"}}} {...addPropertyOverrides({Dzy7ZCHI4: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-5pmcgg"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"ZzDQvs3J8"} style={{backgroundColor: "var(--token-f92a0477-0163-463c-b8d2-581e7fa888a7, rgb(255, 255, 255))", rotate: 0}} variants={{Dzy7ZCHI4: {rotate: 45}}}/><motion.div className={"framer-1h722p9"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"B3YEwoZzn"} style={{backgroundColor: "var(--token-f92a0477-0163-463c-b8d2-581e7fa888a7, rgb(255, 255, 255))", rotate: 0}} variants={{Dzy7ZCHI4: {rotate: -45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lxrah.framer-1kq4o78, .framer-lxrah .framer-1kq4o78 { display: block; }", ".framer-lxrah.framer-dtxns5 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 32px; will-change: var(--framer-will-change-override, transform); }", ".framer-lxrah .framer-5pmcgg { flex: none; height: 1px; left: calc(50.00000000000002% - 18px / 2); overflow: hidden; position: absolute; top: 12px; width: 18px; z-index: 1; }", ".framer-lxrah .framer-1h722p9 { bottom: 12px; flex: none; height: 1px; left: calc(50.00000000000002% - 18px / 2); overflow: hidden; position: absolute; width: 18px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lxrah.framer-dtxns5 { gap: 0px; } .framer-lxrah.framer-dtxns5 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-lxrah.framer-dtxns5 > :first-child { margin-top: 0px; } .framer-lxrah.framer-dtxns5 > :last-child { margin-bottom: 0px; } }", ".framer-lxrah.framer-v-81a1kz .framer-5pmcgg { left: calc(50.00000000000002% - 32px / 2); top: calc(50.00000000000002% - 1px / 2); width: 32px; }", ".framer-lxrah.framer-v-81a1kz .framer-1h722p9 { bottom: unset; left: calc(50.00000000000002% - 32px / 2); top: calc(50.00000000000002% - 1px / 2); width: 32px; }", ".framer-lxrah[data-border=\"true\"]::after, .framer-lxrah [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Dzy7ZCHI4":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"vYAaxf3ak":"click","OFOh88QD2":"click2"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerYor8SOiGy: React.ComponentType<Props> = withCSS(Component, css, "framer-lxrah") as typeof Component;
export default FramerYor8SOiGy;

FramerYor8SOiGy.displayName = "Menu Icon";

FramerYor8SOiGy.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerYor8SOiGy, {variant: {options: ["ckWuiORgz", "Dzy7ZCHI4"], optionTitles: ["Default", "Close"], title: "Variant", type: ControlType.Enum}, vYAaxf3ak: {title: "Click", type: ControlType.EventHandler}, OFOh88QD2: {title: "Click 2", type: ControlType.EventHandler}} as any)

addFonts(FramerYor8SOiGy, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})